
<template>
<div>
  <div v-if="!isLoading">
    <div  v-if="shownUser" class="row account-page g-3">
      <div class="col-md-4 order-md-2">
        <div class="position-sticky" style="top: 90px;">
          <div class="card bg-white overflow-hidden">
            <div class="card-body">
              <div class="">
                <div class="profile-user position-relative d-inline-block mx-auto mb-4">
                  <img :src="absoluteUrl(shownUser.image)"
                    class="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                </div>
                <h5 class="fs-16 mb-1">
                  {{shownUser.first_name}} {{shownUser.last_name}}
                </h5>
                <p class="mb-0"> <span v-if="shownUser.role" class="text-capitalize">{{shownUser.role.name}}</span> / Joined on {{$filters.date(shownUser.created_at, 2)}}</p>
              </div>
            </div>
            <div class="circles-container" ><div class="circle-back" ></div><div class="circle-front" ></div></div>
          </div>
          <div v-if="shownUser.hospital" class="card shadow-sm">
            <div class="card-body">
              <h6>Hospital</h6>
              <p v-if="shownUser.hospital" class="mb-0"> <router-link :to="`/hospitals/${shownUser.hospital.id}`"> <strong>{{shownUser.hospital.name}}</strong></router-link></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row g-3">
          <div class="col-12">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6 class="mb-3 text-uppercase bg-light p-2">
                  Personal Info
                </h6>
                <user-update-form :editItem="shownUser"
                  :editMode="true"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6 class="mb-3 text-uppercase bg-light p-2">
                    Password
                </h6>
                <user-password-form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>
<script>

import IsLoading from '@/components/IsLoading.vue'
import UserUpdateForm  from "@/components/forms/UserUpdateForm.vue"
import UserPasswordForm from "@/components/forms/UserPasswordForm.vue"

export default {
  name: "user-show",
  components: {
    UserUpdateForm,
    UserPasswordForm,
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    }
  },
  methods:{
    fetchItem(){
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
        .then((response) => {
          this.isLoading = false
          if(!response.data.success){
            this.$router.push({path: '/error-404'})
          }
      })
    }
  },
  created() {
    this.$store.dispatch("userList/fetchRoles")
    this.$store.dispatch('fetchCountries')
    this.fetchItem()
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
.circles-container {
    position: absolute;
    bottom: 0;
    top:0;
    width: 20%;
    right: -20%;
}

.circle-back, .circle-front{
    background-color: #e1f6e2;
    position: relative;
    width: 200%;
    padding-top: 200%;
    padding-bottom: 0;
    border-radius: 100%;
    display: inline-block
}
.circle-back {
    opacity: .6;
    z-index: 3;
    margin-left: -108%;
    margin-top: 0
}

.circle-front {
    opacity: .5;
    margin-bottom: 50%;
    margin-left: -209%;
    z-index: 4
}
</style>
